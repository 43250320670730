<template>
  <v-form
    ref="form"
    v-model="valid"
    @input="$emit('valid', $event)"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          label="Name *"
          dense
          v-model="subscriptions.name"
          required
          :rules="[v => !!v || 'field is required']"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <v-select
          label="Connection *"
          dense
          :items="connections"
          item-text="_source.name"
          item-value="_id"
          v-model="subscriptions.connection"
          :rules="[v => !!v || 'field is required']"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
      >
        <v-combobox
          label="Pattern *"
          v-model="subscriptions.pattern"
          hide-selected
          multiple
          small-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Press <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <!-- <small>*indicates required field</small> -->
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/services/nest'
// import cleanDeep from 'clean-deep'
import mqttTemplate from '@/template/mqttTemplate.json'

export default {
  name: 'MqttSubscriptionsForm',
  model: {
    prop: 'subscriptions',
    event: 'input'
  },
  components: {
  },
  props: {
    subscriptions: {
      type: Object,
      default () {
        return JSON.parse(JSON.stringify(mqttTemplate.subscriptions))
      },
      required: false
    },
    connections: {
      type: Array,
      default () {
        return []
      },
      required: false
    },
    connection: {
      type: String
    },
    isCreated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      valid: true
    }
  },
  methods: {
    // fetchMQTTConnections: function () {
    //   let vm = this
    //   api.get(`/api/services/${this.fiwareService}/connections`, null, null, this.$store.state.tokenRPT).then((response) => {
    //     if (response.status === 200) {
    //       response.json().then((json) => {
    //         vm.connections = json
    //       })
    //     }
    //   })
    // },
    resetValidateForm: function () {
      this.$refs.form.resetValidation()
    },
    createSubscription: function () {
      let idx = this.connections.findIndex((c) => c['_id'] === this.subscriptions.connection)
      let connectionName = idx > -1 ? this.connections[idx]['_source']['name'] : '-'
      return api.post(`/api/services/${this.fiwareService}/connections/${connectionName}/subscriptions`, this.subscriptions, null, null, this.$store.state.tokenRPT).then((response) => {
        return response.json()
      })
    }
  },
  computed: {
    ...mapGetters(['fiwareService'])
    // fieldRequire: function () {
    //   return v => !!v || 'field is required'
    // }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    // this.fetchMQTTConnections()
  }
}
</script>
